import { Reducer } from 'redux';

import { IContextActions } from '@interfaces/context/actions';
import { IContextQuery, IContextReducer } from '@interfaces/context/reducer';
import { getUserAgentType } from '@utils/userAgent';

import * as actions from '../../constants/actions/context';
import { initialState } from './initState';

export const contextReducer: Reducer<IContextReducer, IContextActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.CONTEXT_SAVE_QUERY:
      const sexId = action.payload.sexId ? Array.isArray(action.payload.sexId) ? action.payload.sexId : Number(action.payload.sexId) : undefined;
      const newState: IContextQuery = sexId ? {...action.payload, sexId} : action.payload;
      return { ...state, query: newState };
    case actions.CONTEXT_SAVE_DEVICE:
      const uaType = getUserAgentType(action.payload.ua || '');
      return { ...state, userAgent: action.payload, userAgentType: uaType  };
    case actions.CONTEXT_SAVE_HOST:
      return { ...state, host: action.payload };
    default:
      return state;
  }
};
