import { Reducer } from 'redux';

import { ICommonActions } from '@interfaces/common/actions';
import { ICommonReducer } from '@interfaces/common/reducer';
import * as actions from '../../constants/actions/common';
import { initialState } from './initState';

export const commonReducer: Reducer<ICommonReducer, ICommonActions> = (state = initialState, action) => {
  switch (action.type) {
    case actions.TRENDING_PRODUCTS_GET_REQUEST:
      return {
        ...state,
        loaded: { ...state.loaded, trendingProducts: false },
        trendingProducts: [],
      };
    case actions.TRENDING_PRODUCTS_GET_SUCCESS:
      return {
        ...state,
        loaded: { ...state.loaded, trendingProducts: true },
        trendingProducts: action.payload?.products || [],
      };
    case actions.TRENDING_PRODUCTS_GET_FAILED:
      return {
        ...state,
        loaded: { ...state.loaded, trendingProducts: true },
        trendingProducts: [],
      };
    case actions.RECENT_PRODUCTS_GET_SUCCESS:
      return { ...state, loaded: { ...state.loaded, recentProducts: true }, recentProducts: action.payload };
    case actions.CLEAR_RECENT_PRODUCTS_SUCCESS:
      return { ...state, recentProducts: [] };
    case actions.SUITABLE_PRODUCTS_GET_SUCCESS:
      return {
        ...state,
        loaded: { ...state.loaded, suitableProducts: true },
        suitableProducts: action.payload?.products,
      };
    case actions.HOMEPAGE_PRODUCTS_GET_REQUEST:
      return {
        ...state,
        homePageProducts: [],
        loaded: { ...state.loaded, homePageProducts: false },
        loading: { ...state.loaded, homePageProducts: true },
      };
    case actions.HOMEPAGE_PRODUCTS_GET_SUCCESS:
      return {
        ...state,
        homePageProducts: action.payload.products,
        loaded: { ...state.loaded, homePageProducts: true },
        loading: { ...state.loaded, homePageProducts: false },
      };
    case actions.HOMEPAGE_PRODUCTS_GET_FAILED:
      return {
        ...state,
        homePageProducts: [],
        loaded: { ...state.loaded, homePageProducts: true },
        loading: { ...state.loaded, homePageProducts: false },
      };
    case actions.TRANSLATION_MODAL_OPEN:
      return {
        ...state,
        translationModal: {
          isOpen: true,
          key: action.payload?.key,
          options: action.payload?.options,
        },
      };
    case actions.TRANSLATION_MODAL_CLOSE:
      return {
        ...state,
        translationModal: {
          isOpen: false,
          key: '',
          options: null,
        },
      };
    case actions.RECENT_PRODUCTS_STATE:
      return {
        ...state,
        recentProductsState: true,
      };
    default:
      return state;
  }
};
